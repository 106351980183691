import { Container, Row, Col } from "react-bootstrap";
import ra from "../assets/img/resourceallocation.png";
import dm from "../assets/img/DataMigrationarchi.png";
import Optimization from "../assets/img/Optimization.png";

export const Migration = () => {
    return(
        <section className="projectdetails" id="home">
        <Container>
          <Row className="aligh-items-center">
            <Col xs={12} md={12} xl={12}>
              

                <div> 
                  <span className="intro">Project Introduction:</span> 
                  <h1> Data Migration from On-Premise to Azure Cloud</h1>
                    <p>This project aimed to to migrate on-premise data for the organization to Azure Cloud, using Azure services to create a centralized Data Warehouse 
                        for efficient data storage and faster access. By leveraging Azure Data Factory (ADF) for data orchestration, and Azure Synapse Analytics 
                        for data warehousing, this project achieved a robust architecture suited for large-scale data processing and analytics. 
                      </p>
                </div>
             
            </Col>
            
          
            <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
              <h4> Project Objectives:</h4>

                  <p>
                  1.	Analyze the current state and plan the appropriate migartion strategy</p>
<p>2.	Implement a secure and scalable ETL process using ADF and Synapse to manage data ingestion, cleansing, and transformation.</p>
<p>3.	Build a data warehouse in Azure Synapse Analytics to support business intelligence and reporting.</p>


                  
              </div>
           
          </Col>
          <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
              <h4>Project Steps</h4>
<h4>1. Assessment and Planning </h4>
<p>We began by gathering the project requirements and identifying data sources to ensure all relevant information was accounted for.</p>
                  <p>
                  <b>Requirement Analysis: </b><br></br>
                  •	Understanding the business requirements for migration, including data retention policies, SLAs, and compliance needs.
                  <br></br>
                  • Identifying the scope of the migration, such as the number of databases, tables, size of data, and data dependencies.<br></br>
                
</p>
<p>
                  <b>Environment Assessment: </b><br></br>
                  •	Assessing the on-premise SQL Server environment, including database schemas, indexes, stored procedures, views, and triggers.
                  <br></br>
                  • Identifying and documenting all data sources, data types, and data volumes.
                  <br></br>
                  • Determining the compatibility of on-premise SQL Server with Azure Synapse Analytics.
                  <br></br>
                
</p>
<p>
                  <b>Define Migration Strategy:
                  </b><br></br>
                  •	We choosed a suitable migration strategy (e.g., lift-and-shift, phased migration).

                  <br></br>
                  • We planned for data transformation and data cleansing if required.

                  <br></br>
                  • We took consideration of downtime and data synchronization requirements.

                  <br></br>
                
</p>

                  
              </div>
           
          </Col>

          <Col xs={12} md={5} xl={6} className="coldiff">
              
                
              <div> 
               
             
<h4>2. Preparation </h4>
<p>To establish the necessary infrastructure, we started by setting up the following Azure resources:</p>
  <p>
    <b>Provision of Azure Resources:

</b><br></br>
    •	Setting up an Azure Synapse Analytics workspace.
    <br></br>
•	Creation of Azure Data Lake Storage (ADLS) for staging data and backup purposes.
<br></br>
•	Ensuring Azure SQL Server and Synapse Analytics services are provisioned and configured.
<br></br>

</p>                  
<p>
<b>Network Configuration:

</b><br></br>
• Configuring network settings for secure connectivity between on-premise systems and Azure (e.g., VPN, ExpressRoute).<br></br>
•	Setting up Azure Virtual Network (VNet) and ensuring the secure access to Azure resources.
<br></br>
</p>     

<p>
<b>Security and Compliance:

</b><br></br>
• Implementing necessary security measures, such as encryption, role-based access control (RBAC), and managed identities

<br></br>
•	Ensuring compliance with organizational and regulatory standards (GDPR, HIPAA, etc.).<br></br>
</p>          
    </div>
           
          </Col>

<Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={ra} alt="Resources Allocation"/>
<h6> Resources Allocation Architecture Diagram</h6>
                  </div>
              
            </Col>

            <Col xs={12} md={6} xl={5} className="coldiff">
              
           
              <div> 

               <img src={dm} alt="ADF Image"/>
               <h6> Data Migration Process Architecture</h6>

             </div>
         
       </Col>
          <Col xs={12} md={6} xl={7} className="coldiff">
              
                
              <div> 
               
             
<h4>3. Data Migration </h4>
<p>After resource provisioning, we started the implementation of the  migration strategy, by creating data pipelines for data ingestion in ADF. 
  We focused on creating separate ingestion flows for batch and near-real-time data requirements.</p>
  <p>
    <b>Data Extraction:

</b><br></br>
    •	Using Azure Data Factory (ADF) to extract data from the on-premise SQL Server.<br></br>
    • Setting up Integration Runtime (IR) in ADF for on-premise data access.

</p>                  
<p>
<b>Data Transformation:

</b><br></br>
• Using Data Flows in ADF for data transformation tasks (e.g., data type conversion, cleansing, deduplication)<br></br>
•	Utilized SQL Server Integration Services (SSIS) for complex ETL processes.
<br></br>
</p>     

<p>
<b>Data Load:

</b><br></br>
•	Loading transformed data into Azure Synapse Analytics using ADF pipelines.


<br></br>
•	Using COPY statement for efficient bulk loading into Synapse.
<br></br>
</p>     
<p>
 <b>Data Validation:

</b><br></br>
 • Conducting data validation and integrity checks to ensure data accuracy post-migration.


<br></br>
• Comparison of row counts, data types, and values between source and target databases.
<br></br>
</p>     
    </div>
           
          </Col>


          <Col xs={12} md={5} xl={6} className="coldiff">
              
                
              <div> 
               
             
<h4>4. Optimization and Testing </h4>
<p>With data not transformed and cleaned, we proceeded to optimization and testing data in different environment for performance and efficiency.</p>
  <p>
    <b>Performance Tuning:</b><br></br>
    •	Optimizing Synapse Analytics by creating proper indexing, distribution strategies, and partitioning.

<br></br>
• Utilization of Azure Synapse Optimized Tables for improved performance.
<br></br>
</p>                  
<p>
<b>Testing:

</b><br></br>
• We Conducting functional testing to ensure that migrated data is correctly transformed and loaded.<br></br>
•	We used performance testing to evaluate query execution times and system responsiveness.
<br></br>
</p>        
     
    </div>
           
          </Col>

<Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={Optimization} alt="Databricks Image"/>
                    <h6> Data Optimization and Testing Architecture Diagram</h6>

                  </div>
              
            </Col>

            {/* <Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={synapse} alt="Synapse Image"/>
                    <h6> Synapse Analytics Internal Working Architecture Diagram</h6>

                  </div>
              
            </Col> */}
            <Col xs={12} md={5} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>5. Cutover and Post-Migration</h4>
<p>With testing and performance tuning done, we shifted the progress towards the final migration and post-migration.</p>
  <p>
    <b>Final Migration:

</b><br></br>
    •	We planned and executed the final cutover during a low-usage period to minimize business disruption.
<br></br>
• Synchronizing any incremental changes made during the migration process.<br></br>
</p>                  
<p>
<b>User Acceptance Testing (UAT):

</b><br></br>
• We involved business users in UAT to validate the data and functionality.
<br></br>
•	Addressed any issues or discrepancies identified during testing.
<br></br>
</p>     

<p>
<b>Going-Live:

</b><br></br>
• Officially switched over to the Azure Synapse Analytics environment.

<br></br>
• Communicating the cutover completion to all stakeholders.
</p>         
    </div>
           
          </Col>



            
            <Col xs={12} md={5} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>6. Post-Migration Activities</h4>
<p>With data now in the Azure Cloud, we trained and supported the internal team for future proofing and monitering the progress in the new environment. </p>
  <p>
    <b>Monitoring and Support:

</b><br></br>
    •	We set up Azure Monitor and Azure Log Analytics for continuous monitoring of the Synapse environment.
<br></br>
• Created alerts for any anomalies or performance issues.
<br></br>
</p>                  
<p>
<b>Knowledge Transfer and Documentation:

</b><br></br>
• Documented the migration process, configurations, and lessons learned.
<br></br>
•	We provided training to the relevant teams on managing and utilizing the new Azure Synapse environment.
</p>  
<p>
<b>Optimization and Maintenance:

</b><br></br>
• Continuously monitored the system performance and optimize queries.

<br></br>
•	Regularly kept update and maintain the data warehouse to ensure it meets evolving business needs.
</p>     

 </div>
           
          </Col>
{/* 
<Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={rp} alt="Resources Image"/>

                  </div>
              
            </Col> */}

            {/* <Col xs={12} md={5} xl={6} className="coldiff">
              
                
              <div> 
               
             
<h4>7. Business Intelligence Integration and Reporting</h4>
<p>Once the data was available in Synapse, we integrated it with Power BI to enable real-time reporting.</p>
  <p>
    <b>1.	Power BI Connection to Synapse:</b><br></br>
    •	We configured Power BI to connect to Synapse using DirectQuery mode, allowing users to view up-to-date reports without frequent refreshes.
<br></br>
• Datasets in Power BI were linked directly to Synapse tables, enabling self-service reporting for business users.<br></br>
</p>                  
<p>
<b>2.	Building Dashboards:</b><br></br>
• Interactive dashboards and reports were created in Power BI to provide insights on key business metrics.<br></br>
•	We implemented role-based access control, ensuring that each department had access only to the relevant data.
</p>     

 </div>
           
          </Col> */}

{/* <Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={powerbi} alt="Power BI Image"/>
                    <h6> Power BI Connectivity Architecture Diagram</h6>

                  </div>
              
            </Col> */}

            {/* <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>8. Maintenance, Optimization, and Future Enhancements</h4>
<p>To ensure the system remained efficient and secure, we implemented the following maintenance and optimization practices:</p>
  <p>
    <b>1.	Performance Tuning:</b><br></br>
    •	We regularly reviewed pipeline and query performance in ADF and Synapse.

<br></br>
• Query plans and Spark job performance were optimized to reduce processing times and lower costs.<br></br>
</p>                  
<p>
<b>2.	Data Purging and Retention:</b><br></br>
• Purge policies were set up on ADLS Gen2 to delete obsolete data, optimizing storage costs.<br></br>
•	In Synapse, data archival processes were established for historical data beyond retention requirements.<br></br>
</p>     

<p>
<b>3.	Security and Compliance:</b><br></br>
• Data encryption at rest and in transit was enforced to meet regulatory compliance.
<br></br>
•Auditing was enabled in both Synapse and ADF to track access and changes.</p>     
     
    </div>
           
          </Col> */}

{/* <Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={rp} alt="Resources Image"/>

                  </div>
              
            </Col> */}
          </Row>
        </Container>
      </section>



)
}