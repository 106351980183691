import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

// import headerImg2 from "../assets/img/header-img2.svg";
import headerImg2 from "../assets/img/header-img1.svg";

// import dataimg from "../assets/img/dataimg1.svg";
// import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Data Analytics", "Data Science", "AI & ML", "Business Intelligence" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
     // setIndex(1);
      setDelta(500);
    } 
    // else {
    //   setIndex(prevIndex => prevIndex + 1);
    // }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}> 
                <span className="tagline">Super Advance Business Technologies Solutions </span>
                <h1><span className="wrap">{text}</span></h1>
                  <p>At Super Advance Business Technologies Solutions (SATS Solutions), we specialize in providing top-tier data analytics, data science, and cloud solutions. Leveraging leading platforms like Azure, AWS, and others, we empower businesses to harness the full potential of their data for smarter decision-making and innovation.
                    We are committed and qualified to bring data driven decision in the World. 
                    {/* We specialize in the Data Analytic's, Data Science's area and our vision is to change your organization's outlook by utilizing your data that will allow you to make business decisions to enahnce your output. */}
                    </p>
                 {/* <button > <a href="About"> About Us</a> <ArrowRightCircle size={21} /></button> */}
              </div>}
             </TrackVisibility> 
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                 <div className={isVisible ? "animate__animated animate__zoomIn" : ""}> 
                  <img src={headerImg2} alt="Header Img"/> 
                  {/* <img src={dataimg} alt="Header Img"/> */}
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
