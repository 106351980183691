import { Container, Row, Col } from "react-bootstrap";
// import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/SATS-Logo.svg";
// import navIcon1 from "../assets/img/nav-icon1.svg";
// import navIcon2 from "../assets/img/nav-icon2.svg";
// import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={2}>
           {/* <a href="/">
           <img src={logo} alt="Logo" />
            </a>  */}
          
            <p>Contact Us at </p>
            <h6>info@satssolutions.com </h6>
            <span>Ottawa, ON, Canada </span>

          
          </Col>
          <Col size={12} sm={6}>
           {/* <a href="/">
           <img src={logo} alt="Logo" />
            </a>  */}
           

          </Col>
          <Col size={12} sm={4} className="text-center text-sm-end">
            {/* <div className="social-icon">
              <a href="#"><img src={navIcon1} alt="Icon" /></a>
              <a href="#"><img src={navIcon2} alt="Icon" /></a>
              <a href="#"><img src={navIcon3} alt="Icon" /></a>
            </div> */}
            <p>SATS Solutions Copyright 2024. All Rights Reserved</p>
            <span>Icons From <a href="https://www.flaticon.com/"> Flaticons</a></span>

            
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
