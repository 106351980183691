// import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar} from "../Components/NavBar";
import { Footer } from "../Components/Footer";
import {Salesforce} from "../Components/Salesforce";

function SynapsetoSalesforce() {
  return (
    <div>
    <NavBar/>
    <Salesforce/>
    <Footer/>
    </div>
  );
}

export default SynapsetoSalesforce; 