import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import ms from "../assets/img/MS.png";
import project1 from "../assets/img/Project1.svg";
import DataMigration from "../assets/img/DataMigration.svg";
import evandassessment from "../assets/img/EvaluationandAssesment.svg";
import governance from "../assets/img/DataGovernance.svg";
import salesforce from "../assets/img/SynapsetoSalesforce.svg";


import ibm from "../assets/img/IBM2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';



export const Projects = () => {

  const projects = [
    {
     
      title: <a href="/DataLakeDesign" style={{color:"#121212", textDecoration: "none"}}>Data Lakehouse Design in Azure</a>,
      description: "Data Lakehouse & Warehouse Implemenation on Azure Cloud",
      imgUrl: project1,
      URL: <a href="/DataLakeDesign" style={{color:"#121212"}}> Details</a>,
    },
    {
     
      title: <a href="/DataMigration" style={{color:"#121212", textDecoration: "none"}}>Data Migration</a>,
      description: "On-premise to Azure Cloud Data Migration",
      imgUrl: DataMigration,
      URL: <a href="/DataMigration" style={{color:"#121212"}}> Details</a>,
    },
    {
     
      title: <a href="/AssessmentandEvaluationBIReport" style={{color:"#121212", textDecoration: "none"}}>BI Reports</a>,
      description: "Evaluation and Assessment Reports in Power BI",
      imgUrl: evandassessment,
      URL: <a href="/AssessmentandEvaluationBIReport" style={{color:"#121212"}}> Details</a>,
    },
    {
     
      title: <a href="/DataGovernanceandManagement" style={{color:"#121212", textDecoration: "none"}}>Data Governance and Management</a>,
      description: "Framework Design for Sensitive, Private Data",
      imgUrl: governance,
      URL: <a href="/DataGovernanceandManagement" style={{color:"#121212"}}> Details</a>,
    },
    {
     
      title: <a href="/SynapsetoSalesforce" style={{color:"#121212", textDecoration: "none"}}>Data Migration and Integration for Synapse and Salesforce</a>,
      description: "Synapse to Salesforce CRM for data consumers",
      imgUrl: salesforce,
      URL: <a href="/SynapsetoSalesforce" style={{color:"#121212"}}> Details</a>,
    },
    
    // {
    //   title: "Data Science",
    //   description: "Data Mart Design",
    //   imgUrl: projImg4,
    //   URL: <a href="/" style={{color:"#121212"}}> Details</a> ,

  ];

  const clients = [
    {
      title: "Microsoft",
      description: "One of our most prestigious clients we have worked with prevously ",
      imgUrl: ms,
    },
    {
      title: "IBM",
      description: "One of the clients we have collaborated with prevously",
      imgUrl: ibm,
    },
    
  ];


  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}> 
                <h2>Our Clients and Projects</h2>
                <p>We take pride in delivering data-driven solutions that make a tangible impact. Our portfolio showcases a diverse range of successful projects across various industries, each tailored to meet the unique challenges and goals of our clients. From implementing advanced analytics to designing scalable cloud infrastructures, 
                  our projects demonstrate our expertise in transforming data into valuable business outcomes.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Our Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Our Clients</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Our Working</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                  
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          clients.map((client, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...client}
                                />
                            )
                          })
                        }
                      </Row>
                      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p> */}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className="background-image-right" src={colorSharp2} alt="project1"></img> */}
    </section>
  )
}
