// import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import headerImg from "../assets/img/header-img.svg";

// import headerImg2 from "../assets/img/header-img1.svg";
// import img1 from "../assets/img/Datalake-Azure.png"
// import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import pq from "../assets/img/PowerQuery.png";
import visual from "../assets/img/visulas.png";


export const BIReport = () => {
    return(
        <section className="projectdetails" id="home">
        <Container>
          <Row className="aligh-items-center">
            <Col xs={12} md={12} xl={12}>
              

                <div> 
                  <span className="intro">Project Introduction:</span> 
                  <h1> Employee's Assessment and Evaluation BI Report in Microsoft Power BI</h1>
                    <p>The goal of the project was to develop a comprehensive Employee Assessment and Evaluation Report in Power BI, 
                        connected to an Azure Synapse Analytics data warehouse. The report provided HR and management teams with detailed insights into employee performance, 
                        engagement, and development using various metrics, KPIs, and interactive visuals.
                      </p>
                </div>
             
            </Col>
            
          
            <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
              <h4> Project Objectives:</h4>

                  <p>
                  1.	Provide actionable insights into employee performance, productivity, and engagement.
                  </p>
<p>2.	Track key metrics and KPIs related to employee assessment and evaluation.
</p>
<p>3.	Enhance decision-making for HR and management through interactive and visually appealing reports.</p>
<p>4. Leverage Azure Synapse Analytics for scalable and efficient data storage and processing.</p>



                  
              </div>
           
          </Col>
          <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
              <h4>Project Steps</h4>
<h4>1. Data Sources:
</h4>
<p>We began by analyzing data sources for the reports connection. </p>
                  <p>
                  <b>Azure Synapse Analytics: </b><br></br>
                  •	 The primary data source, a Synapse Data Warehouse for housing employee performance, attendance, feedback, and HR data.
                  <br></br>
                
</p>
<p>
                  <b>Azure Data Factory: </b><br></br>
                  • ADF was used for data integration and ETL processes to feed Azure Synapse from various on-premise and cloud-based systems (e.g., HRMS, Payroll systems                  <br></br>
                  
</p>

                  
              </div>
           
          </Col>

          <Col xs={12} md={5} xl={6} className="coldiff">
              
                
              <div> 
               
             
<h4>2. Data Transformation in Power Query:
</h4>
<p>To clean, enrich and eliminate any errors in data from the source, we utilized Power Query Editor to tranform and enhance data to create a efficient and robust Star Schema Data Model.</p>
  <p>
    <b>Data Extraction:
</b><br></br>
    •	We connected to the Azure Synapse Analytics using the connector to pull raw data into Power BI.
    <br></br>
</p>                  
<p>
<b>Data Cleaning and Transformation:
</b><br></br>
• The first step was to remove duplicates and clean null values.
<br></br>
•	Followed by normalization and standardizing data formats (e.g., dates, strings).

<br></br>
• We performed data type conversions and calculations (e.g., calculated fields for derived metrics), where it was necessary.

</p>     

<p>
<b>Star Schema Implementation:
</b><br></br>
• We designed efficient and robust data model, which started with transforming and shaping data into the star schema format.
<br></br>
•	Identifying the fact and dimension tables by analyzing the given data and business requirements of the client. 
<br></br>
•	Followed by creation of relationships between fact and dimension tables.
<br></br>
•	We designed to ensure optimized data load and query performance.

</p>          
    </div>
           
          </Col>

<Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={pq} alt="Resources Allocation"/>
<h6> Power Query Data Transformation Diagram</h6>
                  </div>
              
            </Col>

            <Col xs={12} md={6} xl={5} className="coldiff">
              
           
              <div> 

               <img src={visual} alt="ADF Image"/>
               <h6> Report Creation and Features Diagram</h6>

             </div>
         
       </Col>
          <Col xs={12} md={6} xl={7} className="coldiff">
              
                
              <div> 
               
             
<h4>3. Report Structure and Visuals:
</h4>
<p>After creating a data model, we focused on creating the reporting visual's and dashboards for the requirements set up by the client in the earlier stage.</p>
  <p>
    <b>Dashboard Overview:

</b><br></br>
    Key Performance Indicators (KPIs):
    <br></br>
a. Overall Employee Performance Score.<br></br>
b. Average Engagement Score.<br></br>
c. Attendance Rate.<br></br>
d. Average Training Hours.<br></br>
e. Project Completion Rate.
</p>                  
<p>
<b>High-level Summary Cards:

</b><br></br>
a. Total Employees.<br></br>
b. Department-wise Performance Summary.<br></br>
c. Top Performers
</p>     

<p>
<b>Detailed Employee Performance Section:

</b><br></br>
a. Matrix Visual: Showing detailed performance metrics by department, role, and individual employee.<br></br>
b. Bar/Column Charts: Visualizing performance trends over time (monthly, quarterly).<br></br>
c. Scatter Plot: Correlation between engagement scores and performance outcomes.

</p>     
<p>
 <b>Attendance and Productivity Section:
</b><br></br>
a. Line Chart: Attendance trends over time.<br></br>
b. Heat Map: Attendance patterns by day of the week.<br></br>
c. Pie Chart: Breakdown of productivity by department.
<br></br>

</p> 
<p>
 <b>Project Performance Section:

</b><br></br>
a. Table Visual: Detailed project metrics (e.g., completion rate, feedback scores).<br></br>
b. KPI Indicators: Project completion rates vs targets.

<br></br>

</p>     
    </div>
           
          </Col>


          <Col xs={12} md={5} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>4. Performance Optimization:
</h4>
<p>With the completion of report design and dahsboard creation, the next step was to make sure that report were optimized for performance, accuracy and quality. </p>
  <p>
    <b>Data Model Optimization:</b><br></br>
    • We created star schema to improve query performance.

</p>                  
<p>
<b>Aggregations and Measures:

</b><br></br>
• We pre-aggregated data for faster load times.<br></br>
</p>   
<p>
<b>Performance Analyzer:

</b><br></br>
• We used it to monitor and optimize report performance.<br></br>
</p>        
     
    </div>
           
          </Col>

            <Col xs={12} md={5} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>5. Deployment and Maintenance:
</h4>
<p> The final step included to publish and maintain the report in Power BI Service, 
    with schedule data refreshes, access control and ongoing support for further changes.</p>
  <p>
    <b>Deployment:
</b><br></br>
    •	We publish the Power BI report to the Power BI Service.
    
<br></br>
• We set up scheduled data refreshes using Azure Synapse as the source.
</p>                  
<p>
<b>User Access and Security:
</b><br></br>
• Implementation of row-level security (RLS) to control data access based on roles (e.g., HR, department managers)
</p>     

<p>
<b>Ongoing Support:
</b><br></br>
• We monitored report usage and performance.
<br></br>
• We made sure to update and maintain data models and reports based on feedback and evolving business needs.
</p>         
    </div>
           
          </Col>
          </Row>
        </Container>
      </section>



)
}