// import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar} from "../Components/NavBar";
import { Footer } from "../Components/Footer";
import {BIReport} from "../Components/BIReport";

function AssessmentandEvaluationBIReport() {
  return (
    <div>
    <NavBar/>
    <BIReport/>
    <Footer/>
    </div>
  );
}

export default AssessmentandEvaluationBIReport; 