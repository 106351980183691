import { Container, Row, Col } from "react-bootstrap";
import ra from "../assets/img/resourceallocation.png";
import dm from "../assets/img/DataMigrationarchi.png";
import SytoCRM from "../assets/img/SynapsetoCRM.png";

export const Salesforce = () => {
    return(
        <section className="projectdetails" id="home">
        <Container>
          <Row className="aligh-items-center">
            <Col xs={12} md={12} xl={12}>
              

                <div> 
                  <span className="intro">Project Introduction:</span> 
                  <h1> Data Migration and Integration for Azure Synapse Analytics and Salesforce CRM</h1>
                    <p>The project involved the process of migrating data from both on-premises systems and 
                        various cloud environments to an Azure Synapse Analytics data warehouse. Following the migration, 
                        the Synapse Analytics data warehouse was to connected 
                        to Salesforce CRM, enabling seamless data consumption for business users.
                      </p>
                </div>
             
            </Col>
            
          
          
          <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
              <h4>Project Steps</h4><br></br>
<h4>1. Assessment and Planning </h4>
<p><b>Objective:</b> The process began by understand the existing data landscape, including data sources, volume, structure, and dependencies.</p>

                  <p>
                  <b>Activities:  </b> The steps involved to achieve this are briefly given below.<br></br>
                  •	Inventory of on-premises databases and cloud-based data sources.
                  <br></br>
                  • Evaluation of data types, volume, and current ETL processes.                  <br></br>

                  • Defining data migration goals, including any data transformation and cleansing requirements.                  <br></br>

                  • Identifying key stakeholders and define project roles and responsibilities.

</p>
<p>
                  <b>Deliverable:</b> Data migration strategy document, detailing timelines, resources, and risk management plans.<br></br>
              
</p>


                  
              </div>
           
          </Col>

          <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>2. Architecture Design </h4>
<p><b>Objective:</b> Designing a robust architecture for data migration and integration with Azure Synapse Analytics Warehouse.</p>
  <p>
    <b>Components:

</b><br></br>
    •	Azure Data Factory (ADF): For orchestrating the data migration process.
    <br></br>
•	Azure Synapse Analytics: Centralized data warehouse.
<br></br>
•	Azure Blob Storage: Staging area for data before loading into Synapse.<br></br>

•	Azure SQL Database: For intermediate transformations, if necessary.<br></br>

•	Salesforce CRM: End-user data consumption platform.
</p>                  
<p>
<b>Deliverable: 

</b> Detailed architecture diagram showing data flow from on-premises/cloud sources to Azure Synapse and then to Salesforce CRM.

</p>     

    </div>
           
          </Col>

<Col xs={12} md={12} xl={12} className="coldiff">
              
           
                   <div> 
                    <img src={SytoCRM} alt="Resources Allocation"/>
                    <br></br>
                    <br></br>

<h5 style={{textAlign:"center"}} > Detailed Architecture Diagram Showcasing the Data Flow from Start to Fininsh</h5>
                  </div>
                  <br></br>
              
            </Col>

          <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>3. Setting Up Azure Environment </h4>
<p> <b>Objective:</b> The next step in the migration and integration is configuring the Azure environment for data migration.</p>
  <p>
    <b>Activities:

</b><br></br>
  
•	Setting up Azure Synapse Analytics workspace.<br></br>

•	Configuring Azure Blob Storage for staging data.<br></br>

•	Setting up Azure Data Factory pipelines for data ingestion.<br></br>

•	Establishing connectivity between on-premises systems and Azure using Azure ExpressRoute.

</p>                  
<p>
<b>Deliverable: 

</b> Configured Azure environment to be ready for data migration.
</p>     


    </div>
           
          </Col>


          <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>4. Data Migration Execution </h4>
<p><b>Objective:</b> The process of migrating data from on-premises and cloud sources to Azure Synapse Analytics before any transformation and access to the Salesforce CRM.
</p>
  <p>
    	<b>Activities:</b> <br></br>
      • Developed and executed ADF pipelines to extract, transform, and load (ETL) data.<br></br>
      • Data Migration Assistant (DMA) for schema and data compatibility checks.<br></br>
      • Implemented data transformation logic in Synapse.<br></br>
      • Performed in-depth data validation to ensure accuracy and integrity.

</p>                  
<p>
<b>Deliverable:</b >Successfully migrated data in Azure Synapse Analytics with validation reports.<br></br>
</p>        
     
    </div>
           
          </Col>

            <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>5. Integration with Salesforce CRM</h4>
<p><b>Objective:</b> Setting up and connecting the Salesforce CRM to consume data from Azure Synapse Analytics.</p>
  <p>
    <b>Activities:</b> <br></br>
    •	We used Azure Data Factory to create pipelines that extract data from Synapse and load it into Salesforce objects.<br></br>
    • Configured Salesforce Connect to integrate external data from Synapse via OData.<br></br>
    • We configured scheduled data refresh and ensured data synchronization.<br></br>
</p>                  
<p>
<b>Deliverable: </b> Configured integration with data available in Salesforce CRM for business users.
</p>         
    </div>
           
          </Col>



            
            <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>6. Testing and Validation</h4>
<p><b>Objective:</b> To ensure the integrity and performance of the migrated data and integration we conducted varies test ensure the process ran smoothly.

</p>
  <p>
    <b>Activities:


</b><br></br>
    •	Conducted unit tests on individual ETL processes.<br></br>
    • Performed end-to-end testing for data flow from source systems to Salesforce CRM.<br></br>
    • Validated data accuracy, completeness, and performance benchmarks.
</p>                  
<p>
<b>Deliverable: 
</b> Comprehensive testing reports and a validated data integration setup.
</p>     

 </div>
           
          </Col>


          <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>7. User Training and Documentation</h4>
<p><b>Objective:</b> We equipped users and administrators with knowledge of the new system and trained them to make sure they are familiar with the new environment.
</p>
  <p>
    <b>Activities:
</b><br></br>
    • Conducted training sessions for users on accessing and using data in Salesforce CRM.<br></br>
    • Provided documentation on data migration processes, architecture, and troubleshooting guides.
</p>                  
<p>
<b>Deliverable: 
</b> Training materials and comprehensive documentation.
</p>     

 </div>
           
          </Col>
{/* 
<Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={rp} alt="Resources Image"/>

                  </div>
              
            </Col> */}

            {/* <Col xs={12} md={5} xl={6} className="coldiff">
              
                
              <div> 
               
             
<h4>7. Business Intelligence Integration and Reporting</h4>
<p>Once the data was available in Synapse, we integrated it with Power BI to enable real-time reporting.</p>
  <p>
    <b>1.	Power BI Connection to Synapse:</b><br></br>
    •	We configured Power BI to connect to Synapse using DirectQuery mode, allowing users to view up-to-date reports without frequent refreshes.
<br></br>
• Datasets in Power BI were linked directly to Synapse tables, enabling self-service reporting for business users.<br></br>
</p>                  
<p>
<b>2.	Building Dashboards:</b><br></br>
• Interactive dashboards and reports were created in Power BI to provide insights on key business metrics.<br></br>
•	We implemented role-based access control, ensuring that each department had access only to the relevant data.
</p>     

 </div>
           
          </Col> */}

{/* <Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={powerbi} alt="Power BI Image"/>
                    <h6> Power BI Connectivity Architecture Diagram</h6>

                  </div>
              
            </Col> */}

            {/* <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
             
<h4>8. Maintenance, Optimization, and Future Enhancements</h4>
<p>To ensure the system remained efficient and secure, we implemented the following maintenance and optimization practices:</p>
  <p>
    <b>1.	Performance Tuning:</b><br></br>
    •	We regularly reviewed pipeline and query performance in ADF and Synapse.

<br></br>
• Query plans and Spark job performance were optimized to reduce processing times and lower costs.<br></br>
</p>                  
<p>
<b>2.	Data Purging and Retention:</b><br></br>
• Purge policies were set up on ADLS Gen2 to delete obsolete data, optimizing storage costs.<br></br>
•	In Synapse, data archival processes were established for historical data beyond retention requirements.<br></br>
</p>     

<p>
<b>3.	Security and Compliance:</b><br></br>
• Data encryption at rest and in transit was enforced to meet regulatory compliance.
<br></br>
•Auditing was enabled in both Synapse and ADF to track access and changes.</p>     
     
    </div>
           
          </Col> */}

{/* <Col xs={12} md={8} xl={6} className="coldiff">
              
           
                   <div> 

                    <img src={rp} alt="Resources Image"/>

                  </div>
              
            </Col> */}
          </Row>
        </Container>
      </section>



)
}