import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import rp from "../assets/img/resource-provisioning.png";
import adf from "../assets/img/adf-working.png";
import databricks from "../assets/img/databricks.png";
import synapse from "../assets/img/synapse.png";
import powerbi from "../assets/img/powerbi.png";

export const Governance = () => {
    return(
        <section className="projectdetails" id="home">
        <Container>
          <Row className="aligh-items-center">
            <Col xs={12} md={12} xl={12}>
              

                <div> 
                  <span className="intro">Project Introduction:</span> 
                  <h1> Data Governance and Management Framework Design for Sensitive, Private Data</h1>
                    <p>The project focused on designing and implementing a comprehensive 
                        Data Governance and Management Framework for a company handling 
                        sensitive and private data. The framework ensures data security, 
                        compliance, and efficient data management through defined processes, 
                        sensitivity labeling, thorough documentation, and employee training. 
                      </p>
                </div>
             
            </Col>
            
          
            <Col xs={12} md={12} xl={12} className="coldiff">
              
                
              <div> 
               
              <h4> Project Objectives:</h4>

<p>
 1. Establish a data governance framework to manage sensitive data securely and comply with regulatory requirements (e.g., GDPR, HIPAA).
</p>
<p>2. Classify data sensitivity levels and implement appropriate controls for data access and protection.
</p>
<p>3. Develop detailed documentation for governance policies, processes, and roles.
</p>
<p> 4. Train employees on data governance practices and responsibilities.
</p>
<p> 5. Leverage suitable tools and technologies to automate and enforce data governance policies.
</p>              
    
              </div>           
      </Col>
          <Col xs={12} md={12} xl={12} className="coldiff">
                        
                          
                        <div> 
                         
                        <h4>Project Steps</h4>
          <h4>1. Assessment and Planning </h4>
          <p>We began the Data Governance and Management Framework project by anlyzing the current 
            data and the environment it is stored in, with stakeholders meetings, inventory analysis and defining scope.</p>
                            <p>
         <b>Stakeholder Engagement: </b><br></br>
                            • We Identified key stakeholders, including data owners, IT, legal, compliance, and business units.
                            <br></br>
                            • Continuing with conducting workshops to gather requirements and understand existing data handling practices.<br></br>
                          
          </p>
          <p>
                            <b>Data Inventory and Classification: </b><br></br>
                            • We performed a data inventory analysis to identify all sensitive data assets across the organization.
                            <br></br>
                            • The team classified data based on sensitivity levels (e.g., Public, Internal, Confidential, Highly Confidential).
          </p>
          <p>
                            <b>Defining Objectives and Scope:
                            </b><br></br>
                            •	We defined the scope of the data governance framework, focusing on sensitive data protection and compliance.
                            <br></br>
                            • 	We established clear objectives, such as data privacy, security, integrity, and accessibility.
                            
          </p>
          
                            
                        </div>
                     
                    </Col>
          
                    <Col xs={12} md={12} xl={12} className="coldiff">
                        
                          
                        <div> 
                         
                       
          <h4>2. Framework Design </h4>
          <p>We begin to design the framework for the governance and management after analyzing the current state, we held meetings and understood stakeholder's business requirements. </p>
            <p>
              <b>Governance Structure:
          
          </b><br></br>
              •	Established a Data Governance Committee to oversee the framework's implementation and ongoing management.<br></br>
          •	We sat together with the internal team and stakeholders to define roles and responsibilities, including Data Stewards, Data Custodians, and Data Owners.
          </p>                  
          <p>
          <b>Policies and Procedures:
          </b><br></br>
          • We developed data governance policies, including data classification, access control, data retention, and data sharing policies.<br></br>
          •	Documented the procedures for data handling, incident response, and audit processes.</p>     
          
          <p>
          <b>Sensitivity Labelling:
          
          </b><br></br>
          • Implemented a sensitivity labelling system to classify data based on its confidentiality and impact if disclosed.
          <br></br>
          •	For automating labelling and protection we used Microsoft Information Protection (MIP).
          </p>
          <p>
          <b>Data Access and Security Controls:
          
          </b><br></br>
          • We defined role-based access controls (RBAC) to restrict data access based on sensitivity levels.<br></br>
          •	We implemented encryption, both at rest and in transit, for highly confidential data.</p>          
              </div>
                     
                    </Col>
          
          {/* <Col xs={12} md={8} xl={6} className="coldiff">
                        
                     
                             <div> 
          
                              <img src={ra} alt="Resources Allocation"/>
          <h6> Resources Allocation Architecture Diagram</h6>
                            </div>
                        
                      </Col> */}
{/*           
                      <Col xs={12} md={6} xl={5} className="coldiff">
                        
                     
                        <div> 
          
                         <img src={dm} alt="ADF Image"/>
                         <h6> Data Migration Process Architecture</h6>
          
                       </div>
                   
                 </Col> */}
                    <Col xs={12} md={12} xl={12} className="coldiff">
                        
                          
                        <div> 
                         
                       
          <h4>3. Documentation </h4>
          <p> After defininhg a governance structure and policies for sensitivity labels, data access, we documented policy and inventory classifications. </p>
            <p>
              <b>Policy Documentation:
          
          </b><br></br>
              •	We created comprehensive documentation of data governance policies, roles, and procedures.
              • Ensured policies are easily accessible and regularly updated.
          </p>                  
          <p>
          <b>Data Inventory and Classification Records:
          </b><br></br>
          • We maintained a centralized data catalogue documenting all data assets, classifications, and ownership. 
          •	We used Azure Purview for data discovery and classification.
          </p>     
          
          <p>
          <b>Compliance Documentation:
          </b><br></br>
          •	Documented compliance with relevant regulations and standards, including audit trails and reporting.
          </p>     
              
              </div>
                     
                    </Col>
          
          
                    <Col xs={12} md={12} xl={12} className="coldiff">
                        
                          
                        <div> 
                         
                       
          <h4>4. Technology Implementation </h4>
          <p>With data not transformed and cleaned, we proceeded to optimization and testing data in different environment for performance and efficiency.</p>
            <p>
              <b>Data Catalogue and Discovery Tools:</b><br></br>
              •	We implemented Azure Purview for data cataloguing, lineage tracking, and metadata management.
          
          </p>                  
          <p>
          <b>Data Protection and Privacy Tools:
          
          </b><br></br>
          • We used Microsoft Information Protection (MIP) for sensitivity labelling and data protection.<br></br>
          •	Implemented Data Loss Prevention (DLP) policies using Microsoft 365 DLP.
          </p>    
          <p>
          <b>Access Management:
          </b>
          <br></br>
          • We utilized Azure Active Directory (Azure AD) for identity and access management, ensuring secure authentication and RBAC.</p>    
          <p>
          <b>Monitoring and Reporting:
          </b><br></br>
          • We set up Azure Monitor and Azure Security Center for continuous monitoring of data access and security incidents.</p>        
               
              </div>
                     
                    </Col>
          
          {/* <Col xs={12} md={8} xl={6} className="coldiff">
                        
                     
                             <div> 
          
                              <img src={Optimization} alt="Databricks Image"/>
                              <h6> Data Optimization and Testing Architecture Diagram</h6>
          
                            </div>
                        
                      </Col> */}
          
                      {/* <Col xs={12} md={8} xl={6} className="coldiff">
                        
                     
                             <div> 
          
                              <img src={synapse} alt="Synapse Image"/>
                              <h6> Synapse Analytics Internal Working Architecture Diagram</h6>
          
                            </div>
                        
                      </Col> */}
                      <Col xs={12} md={12} xl={12} className="coldiff">
                        
                          
                        <div> 
                         
                       
          <h4>5. Employee Training</h4>
          <p>With the framework design and implementation now complete, we ensured the internal employees and stakeholders were trained to understand the new framework while also learning the importance of the data governance and management.</p> 
            <p>
              <b>Training Program Development:
          </b><br></br>
              •	We developed a training program to educate employees on data governance policies, sensitivity labelling, and security practices.<br></br>
          • We included modules on data privacy laws, secure data handling, and incident reporting.</p>                  
          <p>
          <b>Training Delivery:
          
          </b><br></br>
          • We ensured to conduct regular training sessions, including onboarding for new employees and refresher courses for existing staff.<br></br>
          •	Used e-learning platforms like Microsoft Learn or custom LMS systems for scalable training delivery.
          </p>     
          
          <p>
          <b>Awareness Campaigns:
          
          </b><br></br>
          • We ran awareness campaigns to reinforce the importance of data governance and encourage a culture of data responsibility.
          </p>         
              </div>
                     
                    </Col>
          
          
          
                      
                      <Col xs={12} md={12} xl={12} className="coldiff">
                        
                          
                        <div> 
                         
                       
          <h4>6. Monitoring and Continuous Improvement</h4>
          <p>With data now in the Azure Cloud, we trained and supported the internal team for future proofing and monitering the progress in the new environment. </p>
            <p>
              <b>Monitoring and Auditing:
          
          </b><br></br>
              •	We implemented regular audits to ensure compliance with data governance policies and regulations.<br></br>
          • Used audit findings to identify areas for improvement and update policies accordingly.</p>                  
          <p>
          <b>Feedback and Iteration:
          </b><br></br>
          • We collected feedback from stakeholders and employees to continuously refine the data governance framework.<br></br>
          •	We made sure to stay updated with evolving regulations and industry best practices to ensure the framework remains effective.</p>  
          <p>
          <b>Automation and Optimization:
          </b><br></br>
          • We leveraged automation tools to streamline data governance processes, such as automated sensitivity labelling and access control enforcement.
          <br></br>
          •	We optimized data governance workflows to reduce manual effort and enhance efficiency.</p>     
          
           </div>
                     
                    </Col>
         
          </Row>
        </Container>
      </section>



)
}