// import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar} from "../Components/NavBar";
import { Footer } from "../Components/Footer";
import {Governance} from "../Components/Governance";

function DataGovernanceandManagement() {
  return (
    <div>
    <NavBar/>
    <Governance/>
    <Footer/>
    </div>
  );
}

export default DataGovernanceandManagement; 