// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import DataLakeDesign from './pages/DataLakeDesign';
import DataMigration from './pages/DataMigration';
import AssessmentandEvaluationBIReport from './pages/AssessmentandEvaluationBIReport';
import DataGovernance from './pages/DataGovernanceandManagement';
import SynapsetoSalesforce from './pages/SynapsetoSalesforce';



import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';




function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route exact path='/' element ={<HomePage/>}/>
        <Route exact path='/About' element ={<About/>}/>
        <Route exact path='/DataLakeDesign' element={<DataLakeDesign/>}/>
        <Route exact path='/DataMigration' element={<DataMigration/>}/>
        <Route exact path='/AssessmentandEvaluationBIReport' element={<AssessmentandEvaluationBIReport/>}/>
        <Route exact path='/DataGovernanceandManagement' element={<DataGovernance/>}/>
        <Route exact path='/SynapsetoSalesforce' element={<SynapsetoSalesforce/>}/>
        


      </Routes>
      </Router>
     {/* <HomePage/> */}
     {/* <About/> */}
         </div>
  );
}

export default App;
